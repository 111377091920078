import styled, { css } from 'styled-components';

import { StyledSwiperProps, SlidesStyled, StyledButtonSwiper, StyledSwiper, ProductName } from '../Styled/CarouselBaseStyles';

export const Container = styled.div`
    position: relative;
`;

export const ProductNameCarousel = styled(ProductName)`
    ${props => props.theme.devices.mobile} {
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-weight: 400;
        font-size: ${props => props.theme.globalSize.size4};
        line-height: 23.4px;
        padding-top: 18px;
    }

    ${props => props.theme.devices.desktop} {
        font-weight: 700;
        margin-top: 40px;
        font-size: ${props => props.theme.globalSize.size4};
        font-family: ${props => props.theme.fonts.familySecondary};
        font-style: normal;
        line-height: 26.04px;
    }
`;

export const StyledSwiperCarousel = styled(StyledSwiper)<StyledSwiperProps>`
    height: 446px;
    width: 99%;
    ${({ swipertranslate }) => css`
        ${props => props.theme.devices.desktop} {
            transform: translate3d(${swipertranslate}px, 0, 0);
            transition: transform 0.1s ease;
        }
    `}
    ${props => props.theme.devices.mobile} {
        height: 228px;
    }
`;

export const Tabs = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    width: 100%;
    letter-spacing: 0em;

    ${props => props.theme.devices.mobile} {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: 20px;
        font-weight: 700;
        line-height: 26.04px;
    }
`;

export const StyledButtonSwiperCarousel = styled(StyledButtonSwiper)`
    .swiper-button-prev {
        top: 40%;
    }
    .swiper-button-next {
        top: 40%;
    }
`;

export const SlidesStyledCarousel = styled(SlidesStyled)`

    .product-image > img {
        width: 200.14px;
        height: 130px;
        ${props => props.theme.devices.mobile} {
            width: 126.21px; 
            height: 81.85px
        }
        border-radius: 14px;
    }
}
`;

export interface CarouselStyles {
    SlidesStyledCarousel: typeof SlidesStyledCarousel;
    StyledSwiperCarousel: typeof StyledSwiperCarousel;
    ProductNameCarousel: typeof ProductNameCarousel;
    StyledButtonSwiperCarousel: typeof StyledButtonSwiperCarousel;
}
